
function init() {
    window.addEventListener('scroll', function(e){
      var distanceY = window.pageYOffset || document.documentElement.scrollTop,
          shrinkOn = 3,
          nav = document.querySelector("nav");
      if (distanceY > shrinkOn) {
        nav.setAttribute("class","navbar-scroll navbar-expand-lg fixed-top");
      } else {
          nav.setAttribute("class","navbar-expand-lg fixed-top");
      }
    });
  }

window.onload = init();

$(document).ready(function () {
   var mySwiper = new Swiper('.swiper-container', {
      pagination: '.swiper-pagination',
      paginationClickable: true,
      slidesPerView: 6,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
      spaceBetween: 15,
            breakpoints: {
       1190: {
            slidesPerView: 6,
            spaceBetween: 40
       },
       1024: {
            slidesPerView: 5,
            spaceBetween: 40
       },
       768: {
            slidesPerView: 4,
            spaceBetween: 30
       },
       640: {
            slidesPerView: 2,
            spaceBetween: 20
       },
       320: {
            slidesPerView: 1,
            spaceBetween: 10
         }
       }
    });
 });

 $(document).ready(function () {
     var homeGallery = new Swiper('.hg-swiper-container', {
        pagination: '.hg-swiper-pagination',
        paginationClickable: true,
        slidesPerView: 6,
        navigation: {
          nextEl: '.hg-swiper-button-next',
          prevEl: '.hg-swiper-button-prev',
      },
        spaceBetween: 15,
              breakpoints: {
         1190: {
              slidesPerView: 6,
              spaceBetween: 40
         },
         1024: {
              slidesPerView: 5,
              spaceBetween: 40
         },
         768: {
              slidesPerView: 4,
              spaceBetween: 30
         },
         640: {
              slidesPerView: 2,
              spaceBetween: 20
         },
         320: {
              slidesPerView: 1,
              spaceBetween: 10
           }
         }
      });
   });

